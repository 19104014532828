.background {
  animation: color-change 4s infinite;
}

@keyframes color-change {
  0% {
    background-color: #15803d;
  }
  50% {
    background-color: #9f1239;
  }
  100% {
    background-color: #15803d;
  }
}