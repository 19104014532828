@import url('https://fonts.googleapis.com/css2?family=Mooli&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  /* outline: 1px solid red; */
  box-sizing: border-box;
  font-family: 'Mooli', sans-serif;
}

head,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  background: #272727;
  color: white;
}